import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

const socket = io("https://socket.story.uranium.work");

let wakeLock = null;

// Request a wake lock
async function requestWakeLock() {
  try {
    wakeLock = await navigator.wakeLock.request('screen');
    //window.alert('Wake lock activated!');
  } catch (err) {
    //window.alert('Failed to acquire wake lock:', err);
  }
}

// Call the requestWakeLock function to activate the wake lock
requestWakeLock();

function Join() {
  const navigate = useNavigate();
  const [lobbyCode, setLobbyCode] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [inLobby, setInLobby] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [playerTyping, setPlayerTyping] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [prevSent, setPrevSent] = useState(''); // Previous Sentence
  const [newSent, setNewSent] = useState(''); // New Sentence
  const [gameEnded, setGameEnded] = useState(false);
  const [bannedSentence, setBannedSentence] = useState(false);
  const [joinError, setJoinError] = useState(null);

  useEffect(() => {
    socket.on('gameStarted', () => {
      console.log('Game started!');
      setGameStarted(true);
    });
  }, []);

  function handleSentenceSubmit() {
    const data = { newSent, lobbyCode };
    socket.emit('newSent', data, (result) => {
      console.log(result);
      if(result.error == "Sentence contains inappropriate words") {
        setBannedSentence(true);
      }
    });
  }

  function handleJoinLobby() {
    const data = { lobbyCode, playerName };
    console.log(data);
    socket.emit('joinLobby', data, (result) => {
      console.log(result);
      if (result.success) {
        console.log('Joined lobby successfully!');
        setInLobby(true);
        socket.isHost = result.isHost; // Set socket.isHost property
      } else {
        setJoinError(result.error);
        console.error('Failed to join lobby.');
      }
    });
  }

  socket.on('endGame', () => {
    setGameEnded(true);
  });

  socket.on('kicked', () => {
    navigate('/');
  });

  socket.on('connect', () => {
    socket.isHost = false; // Default value
  });

  socket.on('startGame', () => {
    console.log("Game Start");
  });

  socket.on('selection', () => {
    setGameStarted(true);
    setPlayerTyping(false);
  });

  socket.on('typingMessage', (args, callback) => {
    if (args == false) {
      console.log("Not selected");
      setIsSelected(false);
    } else {
      console.log("Selected");
      setPrevSent(args);
      setIsSelected(true);

      setNewSent("");
    }

    setPlayerTyping(true);

    //console.log(args);
    console.log(args);
  });

  return (
    <div>
      {gameStarted ? (
        gameEnded ? (
          <div>
            <h1>Game has ended!</h1>
            <h2>Look up at your host's screen to read the story</h2>
          </div>
        ) : (
          playerTyping ? (
            isSelected ? (
              <div class="typebox">
                <h1>Enter a sentence in the box below</h1>
                {prevSent != null ? (
                  <h2>Previous Sentence: {prevSent}</h2>
                ) : (
                  <h2>No previous sentence, start the story!</h2>
                )}
                <input
                  type="text"
                  class="sentence"
                  value={newSent}
                  onChange={(e) => setNewSent(e.target.value)}
                  maxLength={100}
                  onBlur={() => {
                    const regex = /[.!?]/;
                    const sentences = newSent.split(regex);
                    const firstSentence = sentences.find(sentence => sentence.trim().length > 0);
                    if (firstSentence) {
                      setNewSent(firstSentence.trim() + ".");
                    } else {
                      setNewSent("");
                    }
                  }}
                /><br></br>
                <button onClick={handleSentenceSubmit}>Submit</button>
                {bannedSentence ? (
                  <h1>Sentence contains inappropriate words / phrases</h1>
                ) : (
                  <h1></h1>
                )}
              </div>
            ) : (
              <h1>You were not selected this round</h1>
            )
          ) : (
            <h1>Selecting Player...</h1>
          )
        )
      ) : (
        inLobby ? (
          <div>
            <h1>You're in!</h1>
            <p>You should see your name on the host's screen</p>
          </div>
        ) : (
          <div>
            <h1>Join a Game</h1>
            <p>Enter Lobby Code:</p>
            <input
              type="text"
              value={lobbyCode}
              onChange={(e) => setLobbyCode(e.target.value.toUpperCase())}
            />
            <br></br>
            <p>Enter Your Name:</p>
            <input
              type="text"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
            />
            <br></br>
            <br></br>
            <button onClick={handleJoinLobby}>Join</button>
            <p>{joinError}</p>
          </div>
        )
      )}
    </div>
  );
}

export default Join;
