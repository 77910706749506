function About() {


    return (
        <div>
            <main>
                <div style={{ height: "40vh" }}>
                    <h1 style={{ marginTop: "40vh" }}>About StoryStitcher</h1>
                    <p style={{ lineHeight: "20px", maxWidth: "80%", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>StoryStitcher (formerly StorySticher), is a party game for groups of four or more to get together and write stories. The catch? Only the previous sentence is visible. This concept can lead to some very wacky and hilarious stories, such as the one below:</p>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/12.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <h1>First sentence</h1>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>This is where the game begins! This initial sentence can be anything, so we'll use "In the heart of a secluded forest, a curious old oak tree stood tall, its gnarled branches reaching out like ancient fingers towards the sky."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Little did anyone know that within the depths of that old oak tree, a magical portal had remained dormant for centuries, waiting for the chosen one to unlock its secrets and embark on a journey that would change the fate of the enchanted forest forever."</p>
                    </div>
                    <img src="/renders/pro.png" style={{ height: "20rem", margin: 0 }} />
                </div>

                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/14.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"As the sun dipped below the horizon, casting an ethereal glow over the ancient oak, a curious young girl named Lily stumbled upon the hidden portal, its shimmering runes beckoning her with a mysterious allure that whispered of undiscovered wonders and a destiny she could never have imagined."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Intrigued by the pulsating light emanating from the portal, Lily hesitated for only a moment before taking a deep breath and stepping through, leaving behind the familiar world and embarking on a mesmerizing journey through time and space."</p>
                    </div>
                    <img src="/renders/13.png" style={{ height: "20rem", margin: 0 }} />
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/pro2.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"As Lily emerged on the other side, she found herself in a breathtaking realm where vibrant, floating islands drifted gracefully in a sky painted with hues she had never seen before."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"As Lily explored the floating islands, she discovered ethereal beings with shimmering wings, and they welcomed her as the long-awaited visitor destined to restore harmony to their enchanting realm."</p>
                    </div>
                    <img src="/renders/142.png" style={{ height: "20rem", margin: 0 }} />
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/132.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Amidst the enchanting realm, Lily learned of an ancient prophecy foretelling her role in vanquishing the encroaching darkness that threatened to engulf the ethereal beings' celestial paradise."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Guided by the wisdom of an ancient sage, Lily embarked on a perilous quest to find the elusive Crystal of Lumina, the only artifact capable of unlocking her true potential and fulfilling the prophecy."</p>
                    </div>
                    <img src="/renders/pro3.png" style={{ height: "20rem", margin: 0 }} />
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/143.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Venturing through treacherous lands, she encountered mythical creatures and faced harrowing challenges that tested her determination and courage, all while the fate of the realm hung in the balance."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"As the sun set on the horizon, casting a fiery glow over the vast expanse before her, she knew that the final confrontation with the malevolent sorceress would be the ultimate test of her abilities and the pivotal moment that would decide the realm's destiny."</p>
                    </div>
                    <img src="/renders/133.png" style={{ height: "20rem", margin: 0 }} />
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/pro.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Her heart pounded in her chest as she gripped her enchanted staff, ready to face the sorceress and fulfill her sacred duty as the last guardian of the ancient prophecy."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"With determination burning in her eyes, she stepped into the ominous chamber, where the sorceress awaited, surrounded by swirling shadows and crackling magic."</p>
                    </div>
                    <img src="/renders/144.png" style={{ height: "20rem", margin: 0 }} />
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="/renders/132.png" style={{ height: "20rem", margin: 0 }} />
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"As she approached, the sorceress's wicked grin widened, sensing the power within the young woman, unaware of the ancient amulet hidden beneath her tunic, holding the key to unlocking unimaginable forces."</p>
                    </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginLeft: 0, maxWidth: "40%" }}>
                        <p style={{ maxWidth: "70%", lineHeight: "20px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>"Little did the sorceress know that the young woman's destiny was intricately entwined with the fate of the entire realm, and as their paths collided, a cataclysmic battle between darkness and light was set to engulf the world."</p>
                    </div>
                    <img src="/renders/pro.png" style={{ height: "20rem", margin: 0 }} />
                </div>
                <h3 style={{ color: "white", lineHeight: "20px" }}>And that's StoryStitcher! We hope you enjoy playing.</h3>
            </main>
        </div>
    )
}

export default About;