import React from 'react';

function Support() {
    return (
        <div>
            <main>
                <h1>Support</h1>
                <h4 style={{ color: 'white' }}>For all support and questions, please contact support@uranium.work</h4>
            </main>
        </div>
    );
}

export default Support