import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io("https://socket.story.uranium.work/");

function Host() {
  const [lobbyCode, setLobbyCode] = useState('');
  const [players, setPlayers] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [userTyping, setUserTyping] = useState(false);
  const [userDecided, setUserDecided] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [prevPlayers, setPrevPlayers] = useState([]);
  const [story, setStory] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const [sentsRemaining, setSentsRemaining] = useState(null);
  const [sent23, setSent23] = useState(null);
  const [gameEnded, setGameEnded] = useState(false);
  const [storyString, setStoryString] = useState(null);
  const [profanityAllowed, setProfanityAllowed] = useState(false);

  function endStory() {
    socket.emit('endGame', lobbyCode);

    setStoryString(story.join(' '));

    setGameStarted(false);
    setGameEnded(true);
  }

  useEffect(() => {
    console.log(sentsRemaining);
    if(sentsRemaining > 1 || sentsRemaining == 0) {
      setSent23("sentences");
    } else if (sentsRemaining == 1) {
      setSent23("sentence");
    } else if (sentsRemaining == null) {
      console.log("Starting");
    } else {
      endStory();
    }
  }, [sentsRemaining]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server!');
    });
  }, []);

  function handleCreateLobby() {
    socket.emit('createLobby', { profanityAllowed: profanityAllowed }, (lobbyCode) => {
      setLobbyCode(lobbyCode);
    });
  }

  function startGame() {
    if (players.length >= 5) {
      socket.emit('startGame', lobbyCode);
      console.log('Starting game');
      setGameStarted(true);
    } else {
      setButtonClicked(true);
    }
  };

  function skip() {
    socket.emit('update', lobbyCode);

    setUserDecided(false);
    setUserTyping(false);
  }

  function endIn5() {
    setSentsRemaining(5);
  }

  function kick() {
    setIsRemoving(false);
    var data = {
      "lobbyCode": lobbyCode,
      "selectedPlayer": selectedPlayer
    };
    socket.emit('kick', data);
    skip();
  }

  function remove() {
    setIsRemoving(true);
    setStory(story.slice(0, -1));
  }

  socket.on('updatePlayers', (players) => {
    setPlayers((prevPlayers) => [...players]);
  });

  useEffect(() => {
    if(isRemoving == false){
      console.log(story);
      setUserDecided(false);
      setUserTyping(false);

      if(sentsRemaining != null) {
        setSentsRemaining(sentsRemaining - 1);
        console.log(sentsRemaining);
      }
    } else {
      socket.emit('userTyping', [selectedPlayer.id, lobbyCode, story[story.length - 1]], (callback) => {
      console.log(callback);
      });

      setIsRemoving(false);
    }

  }, [story, setUserDecided, setUserTyping, setSentsRemaining]);

  socket.on('newSent', (args, callbac) => {
    setIsRemoving(false);
    setStory([...story, args]);
  });

  useEffect(() => {
    setSelectedPlayer(selectedPlayer);
  }, [selectedPlayer]);

  // Touch this if you're an idiot and hate yourself
  // There should be NOTHING you need to edit here at this point
  useEffect(() => {
    if (gameStarted == true && userDecided == false && isRemoving == false) {
      var filteredPlayers = [];
      var prevPlays = prevPlayers;
      
      if(prevPlayers[0] != null){
        if(prevPlayers.length == 3) {
          console.log("Removing 1");
          prevPlays = prevPlays.slice(1);
        }
        
        filteredPlayers = players.filter(player => player.name !== "Host" && !prevPlays.some(prevPlayer => prevPlayer.name == player.name));
      } else {
        filteredPlayers = players.filter(player => player.name !== "Host");
      }

      console.log("Prev Players: ");
      console.log(prevPlays);
      console.log("Filtered Players: ");
      console.log(filteredPlayers);
    }
    
    let intervalDuration = 50;
    let intervalId;

    const updateSelectedPlayer = () => {
      const randomIndex = Math.floor(Math.random() * filteredPlayers.length);
      setSelectedPlayer(filteredPlayers[randomIndex]);
      intervalDuration += 100;
      if (intervalDuration >= 10000) {
        clearInterval(intervalId);
        setTimeout(function() {
          setUserDecided(true);
          setUserTyping(true);
          console.log(filteredPlayers[randomIndex]);
          if(prevPlayers.length >= 1) {
            console.log("Adding 1: " + prevPlays);
            setPrevPlayers([...prevPlays, filteredPlayers[randomIndex]]);
          } else {
            setPrevPlayers([filteredPlayers[randomIndex]]);
          }
          
          socket.emit('userTyping', [filteredPlayers[randomIndex].id, lobbyCode, story[story.length - 1]], (callback) => {
            console.log(callback);
          });
        }, 5000);
      }
    };

    if (gameStarted == true && userDecided == false && isRemoving == false) {
      setTimeout(function() {
        socket.emit('update', lobbyCode);
        intervalId = setInterval(updateSelectedPlayer, intervalDuration);
      }, 1);
    } else {
      console.log("Not Running");
    }

    return () => clearInterval(intervalId);
  }, [userDecided, players, gameStarted, setSelectedPlayer, story, isRemoving]);

  return (
    <div>
      {gameStarted ? (
        userDecided ? (
          userTyping ? (
            <div>
              <h1>Waiting for {selectedPlayer && selectedPlayer.name} to write their sentence</h1>
              <button onClick={skip}>Skip</button><button onClick={remove}>Delete Preivous</button><br></br>
              <button onClick={kick}>Kick Player</button>
              {sentsRemaining == null ? (
                <button onClick={endIn5}>End in 5</button>
              ) : (
                <div>
                  <br></br><h2>{sentsRemaining} {sent23} remaining</h2>
                </div>
              )}
              <h2>Lobby Code: {lobbyCode}</h2>
            </div>
          ) : (
            <h1>This is an error that should never happen</h1>
          )
        ) : (
          <div>
            <h1>Current player: {selectedPlayer && selectedPlayer.name}</h1>
          </div>
        )
      ) : (
        gameEnded ? (
          <div>
            <h1>Game ended, here is your story!</h1>
            <p>{storyString}</p>
          </div>
        ) : (
          <div>
            <h1>Host a Game</h1>
            {lobbyCode ? (
              <div>
                <h2>Lobby Code: {lobbyCode}</h2>
                <h2>Go to https://story.uranium.work in your browser to join</h2>
                {players.length >= 2 && (
                  <h2>Players in Lobby: {players.length - 1}</h2>
                )}
                {buttonClicked && players.length < 5 && (
                  <p>You need at least 4 players to start the game.</p>
                )}
                <button onClick={startGame}>Start Game</button>
                <ul>
                  {players.filter((player) => player.name !== 'Host').map((player) => (
                    <li key={player.id}>{player.name}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div class="customize">
                  <h2 class="proTex">Profanity Allowed:</h2>
                  <input 
                    type="checkbox" 
                    class="profane" 
                    name="profanityAllowed" 
                    defaultChecked={false}
                    value={profanityAllowed}
                    onChange={(e) => setProfanityAllowed(e.target.checked)}
                    />
                <br />
                <button onClick={handleCreateLobby}>Create Lobby</button>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default Host;
