import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  function joinGame() {
    navigate('/join'); // Use push to navigate to the 'join' route
  }

  function hostGame() {
    navigate('/host'); // Use push to navigate to the 'host' route
  }
  
  //colors: ['#0C79F2', '#0DBDFC', '#00E5E5', '#0DFCB9', '#0CF270'],
  return (
    <div>
      <main>
        <h1>StoryStitcher</h1>
        <p>A fun party game with unknowledged story writing</p>
        <button onClick={hostGame}>Host Game</button><br></br>
        <button onClick={joinGame}>Join Game</button><br></br>
      </main>
      <footer>
        <a href="https://story.uranium.work/about">About StoryStitcher</a>
      </footer>
    </div>
  );
}

export default Home;
