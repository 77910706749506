import React from 'react';

function Privacy() {
    
    return (
            <div>
                <style jsx>{`
                    p {
                        line-height: 20px;
                        max-width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                `}
                </style>
                <main>
                <h1>Privacy Policy</h1>
    <p>Effective Date: 8/7/2023</p>
    <p>Thank you for using StoryStitcher. This Privacy Policy outlines our commitment to maintaining
        your privacy and explains how we handle any information collected or processed during your use of the App. We
        take data privacy seriously and want to assure you that we do not log or store any data whatsoever. This means
        that your personal information and usage data are not collected, retained, or shared by us in any way.</p>

    <h2>1. Information Collection and Use:</h2>
    <p>We want to reiterate that the App does not collect any information from you. As a result, we do not process or
        use any personal data, including but not limited to your name, email address, location, or device
        information.</p>

    <h2>2. Device Permissions:</h2>
    <p>The App does not require or request access to any sensitive permissions on your device. We do not access your
        camera, microphone, location, contacts, or any other personal data.</p>

    <h2>3. Cookies and Tracking Technologies:</h2>
    <p>We do not use cookies or any tracking technologies on our App. Therefore, there is no data collected or shared
        regarding your browsing behavior or interactions with the App.</p>

    <h2>4. Third-Party Services:</h2>
    <p>As we do not collect any data, we do not share any information with third-party services, analytics platforms,
        or advertising networks.</p>

    <h2>5. Data Security:</h2>
    <p>Since we do not store or process any data, there are no concerns related to data security. We have implemented
        reasonable technical and organizational measures to ensure the privacy and security of your information, even
        though we don't collect any.</p>

    <h2>6. Changes to the Privacy Policy:</h2>
    <p>While we do not anticipate any changes to our privacy practices since we do not collect data, any future changes
        will be reflected in this Privacy Policy, and the updated version will be available within the App.</p>

    <h2>7. Contact Us:</h2>
    <p>If you have any questions, concerns, or requests related to this Privacy Policy or your use of the App, please
        feel free to contact us at support@uranium.work.</p>

    <p>By using the App, you agree to the terms outlined in this Privacy Policy. Please refrain from using the App if
        you do not agree with any part of this policy. We appreciate your trust in us to respect your privacy and
        assure you that we remain committed to providing you with a safe and data-free experience.</p>

                </main>
            </div>
    )
}

export default Privacy