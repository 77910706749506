import './App.css'
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home.jsx';
import Host from './Host.jsx';
import Join from './Join.jsx';
import About from './About.jsx'
import Privacy from './Privacy';
import Support from './Support';

class App extends Component {
  constructor(props){
    super(props);
    this.joinGame = this.joinGame.bind(this);
    this.hostGame = this.hostGame.bind(this);
  }
  joinGame() {
    window.location.href="https://storysticher.com/join";
  }
  hostGame() {
    window.location.href="https://storysticher.com/host";
  }
  
  //colors: ['#0C79F2', '#0DBDFC', '#00E5E5', '#0DFCB9', '#0CF270'],
  render() {
    return (  
      <main>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/host" element={<Host />} />
          <Route exact path="/join" element={<Join />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/support" element={<Support />} />
        </Routes>
      </main>
    );
  }
}

export default App;